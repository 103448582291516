import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { DateRange } from "react-date-range";
import { useEffectSkipFirst } from "../../../hooks/useEffectSkipFirst";
import { KTSVG } from "../../../_metronic/helpers";
import { PickerRangeType } from "../batches/batches-list/components/header/UsersListFilter";
import { createJackpot, editJackpot } from "./requests";
import { JackpotItem } from "./JackpotTable";

interface ModalProps {
  onToggle: (value: boolean) => void;
  operatorId: string;
  itemForUpdate: string;
  closeModal: () => void;
  emitReloadList: () => void;
  updateItem?: JackpotItem;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Jackpot title is required"),
  amount: Yup.number()
    .min(0.01, "Jackpot amount must be greater than 0")
    .required("Jackpot amount is required"),
});

const CreateJackpotModal = ({ onToggle, operatorId, updateItem, closeModal, emitReloadList }: ModalProps) => {
  const [createError, setCreateError] = useState("");

  const formik = useFormik({
    initialValues: {
      name: updateItem ? updateItem.name : "",
      amount: updateItem ? updateItem.amount : 0,
      operatorId,
      startAt: updateItem ? updateItem.startAt : "",
      endAt: updateItem ? updateItem.endAt : "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setCreateError("");
      setSubmitting(true);
      try {
        setSubmitting(true);
        if (!updateItem) {
          await createJackpot({
            ...values,
            startAt: moment(values.startAt).format("YYYY-MM-DD HH:mm:ss"),
            endAt: moment(values.endAt).format("YYYY-MM-DD HH:mm:ss"),
          });
          closeModal();
          emitReloadList();
        } else {
          await editJackpot({
            ...values,
            startAt: moment(values.startAt).format("YYYY-MM-DD HH:mm:ss"),
            endAt: moment(values.endAt).format("YYYY-MM-DD HH:mm:ss"),
            id: updateItem.id,
          });
          closeModal();
          emitReloadList();
        }
      } catch (ex) {
        setCreateError("Request error");
      }
    },
  });

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffectSkipFirst(() => {
    console.log(endDate, startDate, 'TIME')
    formik.setFieldValue("endAt", moment.utc(endDate).format());
    formik.setFieldValue("startAt", moment.utc(startDate).format());
    setState([{ startDate, endDate, key: "selection" }]);
  }, [endDate, startDate]);

  const setTimeSelection = (item: PickerRangeType) => {
    const { endDate, startDate } = item.selection;
    setStartDate(startDate);
    setEndDate(endDate);
    setState([item.selection]);
  };

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">{updateItem?.id ? "Update Jackpot" :  "Create Jackpot"}</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                onClick={() => onToggle(false)}
                style={{ cursor: "pointer" }}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {/* <BurninngItemEditModalFormWrapper /> */}
              <form
                id="kt_modal_add_user_form"
                className="form"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <label
                  className="form-label fs-6 fw-bolder text-dark"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": formik.touched.name && formik.errors.name },
                    {
                      "is-valid": formik.touched.name && !formik.errors.name,
                    }
                  )}
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.name}</span>
                  </div>
                )}

                <label
                  className="form-label fs-6 fw-bolder text-dark"
                  htmlFor="amount"
                >
                  Amount
                </label>
                <input
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    {
                      "is-invalid":
                        formik.touched.amount && formik.errors.amount,
                    },
                    {
                      "is-valid":
                        formik.touched.amount && !formik.errors.amount,
                    }
                  )}
                  id="amount"
                  name="amount"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                />

                {formik.touched.amount && formik.errors.amount && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.amount}</span>
                  </div>
                )}

                <label
                  className="form-label fs-6 fw-bolder text-dark"
                  htmlFor="dataRange"
                >
                  Date Range
                </label>
                <br />
                <DateRange
                  editableDateInputs={true}
                  /* @ts-ignore */
                  onChange={setTimeSelection}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
                <br />

                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.touched
                  }
                >
                  <span className="indicator-label">Submit</span>
                  {formik.isSubmitting && (
                    <span className="indicator-progress">
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                {createError && (
                  <div className="text-danger">{createError}</div>
                )}
              </form>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { CreateJackpotModal };
