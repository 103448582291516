import Cookies from "js-cookie";
import { authInstance } from "../auth/core/_requests";

export interface CreateJackpotData {
  name: string;
  amount: number;
  operatorId: string;
  startAt: string;
  endAt: string;
}

export interface EditJackpotData extends CreateJackpotData {
  id: string;
}

export interface JackpotId {
  id: string;
}

export interface JackpotAddWinner {
  jackpotId: string;
  userId: string;
  amount: number;
  place: number;
}

export const createJackpot = (query: CreateJackpotData): any => {
  return authInstance
    .post(`${process.env.REACT_APP_API_URL}/admin/add-jackpot`, query, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.count };
    });
};

export const editJackpot = (query: EditJackpotData): any => {
  return authInstance
    .post(`${process.env.REACT_APP_API_URL}/admin/edit-jackpot`, query, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.count };
    });
};

export const startJackpot = (query: JackpotId): any => {
  return authInstance
    .post(`${process.env.REACT_APP_API_URL}/admin/start-jackpot`, query, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.count };
    });
};

export const endJackpot = (query: JackpotId): any => {
  return authInstance
    .post(`${process.env.REACT_APP_API_URL}/admin/end-jackpot`, query, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.count };
    });
};

export const cancelJackpot = (query: JackpotId): any => {
  return authInstance
    .post(`${process.env.REACT_APP_API_URL}/admin/cancel-jackpot`, query, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.count };
    });
};

export const addWinnerJackpot = (query: JackpotAddWinner): any => {
  return authInstance
    .post(
      `${process.env.REACT_APP_API_URL}/admin/add-winner-to-jackpot`,
      query,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    )
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.count };
    });
};
