import clsx from "clsx";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { addWinnerJackpot } from "./requests";

const validationSchema = Yup.object().shape({
  userId: Yup.string().required("User ID is required"),
  amount: Yup.number()
    .min(0.01, "Amount must be greater than 0")
    .required("Amount is required"),

  place: Yup.number().min(1, "").max(3, "").required("Amount is required"),
});

interface JackpotAddWinnerModalContentProps {
  closeModal: () => void;
  emitReloadList: () => void;
  jackpotId: string;
}

export const JackpotAddWinnerModalContent = ({
  closeModal,
  emitReloadList,
  jackpotId,
}: JackpotAddWinnerModalContentProps) => {
  const [createError, setCreateError] = useState("");

  const formik = useFormik({
    initialValues: {
      userId: "",
      amount: "",
      place: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setCreateError("");
        setSubmitting(true);
        await addWinnerJackpot({
          ...values,
          place: Number(values.place),
          amount: Number(values.amount),
          jackpotId,
        });
        closeModal();
        emitReloadList();
      } catch (ex) {
        setCreateError("Request error");
      }
    },
  });

  return (
    <form
      id="kt_modal_add_user_form"
      className="form"
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <label className="form-label fs-6 fw-bolder text-dark" htmlFor="userId">
        UserID
      </label>
      <input
        className={clsx(
          "form-control form-control-lg form-control-solid",
          { "is-invalid": formik.touched.userId && formik.errors.userId },
          {
            "is-valid": formik.touched.userId && !formik.errors.userId,
          }
        )}
        id="userId"
        name="userId"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.userId}
      />

      {formik.touched.userId && formik.errors.userId && (
        <div className="fv-plugins-message-container">
          <span role="alert">{formik.errors.userId}</span>
        </div>
      )}

      <label className="form-label fs-6 fw-bolder text-dark" htmlFor="amount">
        Amount
      </label>
      <input
        className={clsx(
          "form-control form-control-lg form-control-solid",
          {
            "is-invalid": formik.touched.amount && formik.errors.amount,
          },
          {
            "is-valid": formik.touched.amount && !formik.errors.amount,
          }
        )}
        id="amount"
        name="amount"
        type="number"
        onChange={formik.handleChange}
        value={formik.values.amount}
      />

      {formik.touched.amount && formik.errors.amount && (
        <div className="fv-plugins-message-container">
          <span role="alert">{formik.errors.amount}</span>
        </div>
      )}

      <label className="form-label fs-6 fw-bolder text-dark" htmlFor="place">
        Place
      </label>
      <input
        className={clsx(
          "form-control form-control-lg form-control-solid",
          {
            "is-invalid": formik.touched.place && formik.errors.place,
          },
          {
            "is-valid": formik.touched.place && !formik.errors.place,
          }
        )}
        id="place"
        name="place"
        type="number"
        onChange={formik.handleChange}
        value={formik.values.place}
      />

      {formik.touched.place && formik.errors.place && (
        <div className="fv-plugins-message-container">
          <span role="alert">{formik.errors.place}</span>
        </div>
      )}

      <button
        type="submit"
        className="btn btn-primary"
        data-kt-users-modal-action="submit"
        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
        style={{margin: '8px 0'}}
      >
        <span className="indicator-label">Submit</span>
        {formik.isSubmitting && (
          <span className="indicator-progress">
            Please wait...{" "}
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
      {createError && <div className="text-danger">{createError}</div>}
    </form>
  );
};
