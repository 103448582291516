import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { getOperatorList } from "../operators/users-list/core/_requests";
import { KTSVG } from "../../../_metronic/helpers";
import { CreateJackpotModal } from "./CreateJackpotModal";
import { JackpotTable } from "./JackpotTable";

const JackpotPage = () => {
  const [operators, setOperatorList] = useState([]);
  const [activeOperator, setActiveOperator] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [jackpotList, setJackpotList] = useState<undefined | []>();
  const fetchOperatorJackpot = async () => {
    setIsLoading(true);
    setError("");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-jackpots-by-operator?operatorId=${activeOperator}&page=1&limit=10000`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      setJackpotList(response.data);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeOperator) {
      fetchOperatorJackpot();
    }
  }, [activeOperator]);

  useEffect(() => {
    const fetchOperatorList = async () => {
      const res = await getOperatorList("");
      setOperatorList(res.data);
      //   setOperatorList(res.data.map((item, index)))
    };

    fetchOperatorList();
  }, []);

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [itemForUpdate, setItemForUpdate] = useState('');

  return (
    <div className="mb-10">
      <label className="form-label fs-6 fw-bold">Select operator:</label>
      <select
        id="operatorResourceId"
        name="operatorResourceId"
        className="form-select form-select-solid fw-bolder"
        data-kt-select2="true"
        data-placeholder="Operator Resource Id"
        data-allow-clear="true"
        data-kt-user-table-filter="operatorResourceId"
        data-hide-search="true"
        onChange={(value) => {
          setActiveOperator(value.target.value);
        }}
        value={activeOperator}
      >
        <option value=""></option>
        {Array.isArray(operators) &&
          operators.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
      </select>
      {error && <div className="text-danger">{error}</div>}
      {
        Array.isArray(jackpotList) && jackpotList.length > 0 && (<JackpotTable data={jackpotList} emitItemForUpdate={
          (id) => {
            setIsOpenCreateModal(true);
            setItemForUpdate(id);
          }
        } emitReloadList={() => {fetchOperatorJackpot()}} />)
      }

      {Array.isArray(jackpotList) && jackpotList.length === 0 && (
        <div style={{ width: "100%", margin: "20px auto" }}>NO RECORDS</div>
      )}

      {activeOperator && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setIsOpenCreateModal(true);
          }}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr075.svg"
            className="svg-icon-2"
          />
          Add JACKPOT
        </button>
      )}

      {isOpenCreateModal && (
        <CreateJackpotModal
          onToggle={(value: boolean) => setIsOpenCreateModal(value)}
          operatorId={activeOperator}
          itemForUpdate={itemForUpdate}
          closeModal={() => setIsOpenCreateModal(false)}
          emitReloadList={() => {fetchOperatorJackpot()}}
          //@ts-ignore
          updateItem={(itemForUpdate && Array.isArray(jackpotList) && jackpotList.length > 0) && jackpotList?.find((item) => item.id === itemForUpdate)}
        />
      )}
    </div>
  );
};

export default JackpotPage;
