/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { BatchesChart } from "./BatchesChart";
import { MainStatsChart } from "./MainStatsChart";
import { EventsStatsChart } from "./EventsStatsChart";
import axios from "axios";
import { API_URL } from "../../modules/auth/core/_requests";
import Cookies from "js-cookie";
import { TotalStatsWidget } from "./TotalStatsWidget";
import { ANAUTHORIZED_CODE } from "../../../hooks/useErrorsHandler";
import { useAuth } from "../../modules/auth";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFormik } from "formik";
import clsx from "clsx";

const ResetBlock = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

const uuidSchema = Yup.object().shape({
  uuid: Yup.string()
    // .uuid('wrong uuid format')
    .required("User uuid is required"),
});

const initialValues = {
  uuid: "",
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

enum DataSetEnum {
  SEVEN_DAYS = "seven_days",
  TWENTY_FOUR_HOURS = "twenty_four_hours",
}

export enum DashboardDataStatsNames {
  totalEvents = "totalEvents",
  totalAmount = "totalAmount",
  sentToPool = "sentToPool",
  sentToUser = "sentToUser",
}

export type DashboardDataStats = {
  totalEvents: number | null;
  totalAmount: number | null;
  sentToPool: number | null;
  sentToUser: number | null;
};

export type DashboardData = {
  totalEvents: number | null;
  totalAmount: number | null;
  sentToPool: number | null;
  sentToUser: number | null;
  history: {
    date: string;
    value: DashboardDataStats;
  }[];
};

export function resetUser(uuid: string) {
  return axios.post(
    process.env.REACT_APP_API_URL + "/admin/resend-client",
    {
      clientId: uuid,
    },
    {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }
  );
}

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const initDashboardData: DashboardData = {
    history: [],
    sentToPool: null,
    sentToUser: null,
    totalAmount: null,
    totalEvents: null,
  };
  const [dashboardData, setDashboardData] =
    useState<DashboardData>(initDashboardData);

  const navigate = useNavigate();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const getStats = async () => {
      try {
        const response: any = await axios.get(`${API_URL}/dashboard/get`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        });
        setDashboardData(response.data);
      } catch (error) {
        setDashboardData(initDashboardData);
        //@ts-ignore
        const { status } = error?.response?.data;

        if (status === ANAUTHORIZED_CODE) {
          logout();
          navigate({ pathname: "/auth" });
        }
      }
    };

    getStats();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: uuidSchema,
    onSubmit: async ({ uuid }) => {
      setError("");
      setLoading(true);
      resetUser(uuid)
        .then(() => {
          setLoading(false);
          formik.resetForm();
        })
        .catch((err) => {
          console.log(err.message);
          setError(err.message);
          setLoading(false);
        });
    },
  });

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <div className="col-xl-8">
        <EventsStatsChart dashboardData={dashboardData} />
        <MainStatsChart dashboardData={dashboardData} />
        <TotalStatsWidget dashboardData={dashboardData} />
        <BatchesChart />

        <ResetBlock>
          <form
            className="form w-100"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
          >
            <div className="d-flex flex-column" style={{ width: "100%" }}>
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                  Reset user
                </label>
                <input
                  placeholder="ClientId"
                  {...formik.getFieldProps("uuid")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": formik.touched.uuid && formik.errors.uuid },
                    {
                      "is-valid": formik.touched.uuid && !formik.errors.uuid,
                    }
                  )}
                  type="uuid"
                  name="uuid"
                  autoComplete="off"
                />
                {formik.touched.uuid && formik.errors.uuid && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.uuid}</span>
                  </div>
                )}
              </div>

              {error && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert" style={{ color: "#f44336" }}>
                      {error}
                    </span>
                  </div>
                </div>
              )}

              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <span className="indicator-label">Reset User</span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </ResetBlock>
      </div>
    </>
  );
};

export { DashboardWrapper };
