import moment from "moment";
import react, { useState } from "react";
import styled from "styled-components";
import { cancelJackpot, endJackpot, startJackpot } from "./requests";
import { JackpotAddWinnerModalContent } from "./JackpotAddWinnerModalContent";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const StyledTableHeader = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f2f2f2;
`;

export const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const StyledTableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  min-width: 120px;
`;

export interface JackpotItem {
  amount: number;
  createdAt: string;
  endAt: string;
  id: string;
  name: string;
  operatorId: string;
  startAt: string;
  statusId: number;
  updatedAt: string;
}

interface JackpotLineProps {
  data: JackpotItem[];
  emitItemForUpdate: (id: string) => void;
  emitReloadList: () => void;
}

enum ModalOpen {
  NONE = "NONE",
  HANDLE_START = "HANDLE_START",
  HANDLE_END = "HANDLE_END",
  HANDLE_CANCEL = "HANDLE_CANCEL",
  ADD_WINNER = "ADD_WINNER",
}

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const StyledModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
`;

export const StyledModalButton = styled.button`
  margin-top: 10px;
  padding: 8px 16px;
  background: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 2px;
`;

export const JackpotTable: React.FC<JackpotLineProps> = ({
  data,
  emitItemForUpdate,
  emitReloadList,
}) => {
  const handleStart = async (id: string) => {
    try {
      const result = await startJackpot({ id });
      emitReloadList();
      setModalOpen(ModalOpen.NONE);
    } catch (error) {
      console.error(`Error jackpot with ID ${id}:`, error);
    }
  };

  const handleEnd = async (id: string) => {
    try {
      const result = await endJackpot({ id });
      emitReloadList();
      setModalOpen(ModalOpen.NONE);
    } catch (error) {
      console.error(`Error jackpot with ID ${id}:`, error);
    }
  };

  const handleCancel = async (id: string) => {
    try {
      const result = await cancelJackpot({ id });
      emitReloadList();
      setModalOpen(ModalOpen.NONE);
    } catch (error) {
      console.error(`Error jackpot with ID ${id}:`, error);
    }
  };

  const handleAddWinner = async (id: string) => {
    emitReloadList();
    // Implement the logic for adding a winner to the jackpot with the given id
    console.log(`Add Winner to Jackpot with ID: ${id}`);
  };

  const [modalOpen, setModalOpen] = useState(ModalOpen.NONE);
  const [activeItem, setActiveItem] = useState("");

  return (
    <div>
      <StyledTable>
        <thead>
          <StyledTableRow>
            <StyledTableHeader>ID</StyledTableHeader>
            <StyledTableHeader>Name</StyledTableHeader>
            <StyledTableHeader>Amount</StyledTableHeader>
            <StyledTableHeader>Start Date</StyledTableHeader>
            <StyledTableHeader>End Date</StyledTableHeader>
            <StyledTableHeader>Status ID</StyledTableHeader>
            <StyledTableHeader>Created At</StyledTableHeader>
            <StyledTableHeader>Updated At</StyledTableHeader>
            <StyledTableHeader>Update</StyledTableHeader>
            <StyledTableHeader>Start</StyledTableHeader>
            <StyledTableHeader>End</StyledTableHeader>
            <StyledTableHeader>Cancel</StyledTableHeader>
            <StyledTableHeader>Add Winner</StyledTableHeader>
          </StyledTableRow>
        </thead>
        <tbody>
          {data.map((item) => (
            <StyledTableRow>
              <StyledTableCell>{item.id}</StyledTableCell>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell>{item.amount}</StyledTableCell>
              <StyledTableCell>
                {moment(item.startAt).format("YYYY-MM-DD HH:mm:ss")}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item.endAt).format("YYYY-MM-DD HH:mm:ss")}
              </StyledTableCell>
              <StyledTableCell>{item.statusId}</StyledTableCell>
              <StyledTableCell>
                {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
              </StyledTableCell>
              <StyledTableCell>
                {moment(item.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
              </StyledTableCell>
              <StyledTableCell>
                <button
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={() => {
                    emitItemForUpdate(item.id);
                  }}
                >
                  Update
                </button>
              </StyledTableCell>
              <StyledTableCell>
                <button
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={() => {
                    setActiveItem(item.id);
                    setModalOpen(ModalOpen.HANDLE_START);
                  }}
                >
                  Start
                </button>
              </StyledTableCell>
              <StyledTableCell>
                <button
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={() => {
                    setActiveItem(item.id);
                    setModalOpen(ModalOpen.HANDLE_END);
                  }}
                >
                  End
                </button>
              </StyledTableCell>
              <StyledTableCell>
                <button
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={() => {
                    setActiveItem(item.id);
                    setModalOpen(ModalOpen.HANDLE_CANCEL);
                  }}
                >
                  Cancel
                </button>
              </StyledTableCell>
              <StyledTableCell>
                <button
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={() => {
                    setActiveItem(item.id);
                    setModalOpen(ModalOpen.ADD_WINNER);
                  }}
                >
                  Add Winner
                </button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>

      {modalOpen !== ModalOpen.NONE && (
        <StyledModalOverlay>
          <StyledModalContent>
            <h2>Confirmation</h2>

            {(modalOpen === ModalOpen.ADD_WINNER && (
              <>
                <JackpotAddWinnerModalContent
                  jackpotId={activeItem}
                  closeModal={() => setModalOpen(ModalOpen.NONE)}
                  emitReloadList={emitReloadList}
                />
              </>
            )) ||
              (modalOpen === ModalOpen.HANDLE_CANCEL && (
                <p>Do you want to CANCEL?</p>
              )) ||
              (modalOpen === ModalOpen.HANDLE_END && (
                <p>Do you want to END?</p>
              )) ||
              (modalOpen === ModalOpen.HANDLE_START && (
                <p>Do you want to START?</p>
              ))}
            {/* <p>Do you want to perform this action?</p> */}
            {modalOpen !== ModalOpen.ADD_WINNER && (
              <StyledModalButton
                onClick={() => {
                  if (modalOpen === ModalOpen.HANDLE_CANCEL) {
                    handleCancel(activeItem);
                  } else if (modalOpen === ModalOpen.HANDLE_END) {
                    handleEnd(activeItem);
                  } else if (modalOpen === ModalOpen.HANDLE_START) {
                    handleStart(activeItem);
                  }
                }}
              >
                Yes
              </StyledModalButton>
            )}
            <StyledModalButton onClick={() => setModalOpen(ModalOpen.NONE)}>
              No
            </StyledModalButton>
          </StyledModalContent>
        </StyledModalOverlay>
      )}
    </div>
  );
};
